.required-text{
    margin-bottom: 0.5em;
    font-weight: bold;
}
.btn-close{
    margin-right: 0.5em;
}
.message{
    margin-left: 1em;
}
.firstName{
    width: 100%;
}
.lastName{
    width: 100%;
}
.email{
    width: 100%;
}
.contactNumber{
    width: 100%;
}
.selectedModel{
    width: 100%;
}