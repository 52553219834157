.ipcs {
  background-image: url("../media/96ebded5f1664447974c6bdf518238d3.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.jumbotron h1 {
  font-family: "Opens Sans" sans-serif;
  text-transform: uppercase;
}

.backgroundText {
  font-size: larger;
  font-weight:900;
  font-style: italic;
  color: white;  
  text-shadow: black 0px 0px 10px;
}

.whatsapp {
  width: 34px;
  height: 34px;
}

.whatsappButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.loader {
  text-align: center;
}

.hidden { 
  display:none; 
}