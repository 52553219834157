body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Move down content because we have a fixed navbar that is 3.5rem tall */
body {
  padding-top: 3.5rem;
}
.followUs{
    width: 34px;
    height: 34px;
}
.contactUs{
    padding-left: 7px;
}
.email{
    float: left;
}
.ipcs {
  background-image: url(/static/media/96ebded5f1664447974c6bdf518238d3.8c68a16c.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.jumbotron h1 {
  font-family: "Opens Sans" sans-serif;
  text-transform: uppercase;
}

.backgroundText {
  font-size: larger;
  font-weight:900;
  font-style: italic;
  color: white;  
  text-shadow: black 0px 0px 10px;
}

.whatsapp {
  width: 34px;
  height: 34px;
}

.whatsappButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.loader {
  text-align: center;
}

.hidden { 
  display:none; 
}
.required-text{
    margin-bottom: 0.5em;
    font-weight: bold;
}
.btn-close{
    margin-right: 0.5em;
}
.message{
    margin-left: 1em;
}
.firstName{
    width: 100%;
}
.lastName{
    width: 100%;
}
.email{
    width: 100%;
}
.contactNumber{
    width: 100%;
}
.selectedModel{
    width: 100%;
}

